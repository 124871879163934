import React from 'react';
import PropTypes from 'prop-types';
import { PaymentType } from '@reservations/components';
import { NumberInput } from '@casanova/casanova-common';
import { cashInitialValues } from './config';
import useMultiPayment from '../hooks/useMultiPayment';

const CashMethod = ({ name }) => {
  const { handleMethodChange, hasUpdateDifMethods } = useMultiPayment();

  return (
    <PaymentType
      name={name}
      onSelect={handleMethodChange}
      initialValues={cashInitialValues}
      hasUpdateDifMetods={hasUpdateDifMethods}
      title="PAGO EN EFECTIVO"
      withoutContent={!hasUpdateDifMethods && true}
    >
      {hasUpdateDifMethods && (
        <div className="row">
          <div className="col-12">
            <NumberInput
              decimals={2}
              max={9_999_999}
              label="Monto en Efectivo"
              placeholder="Monto en Efectivo"
              name="amountCash"
              disabled={!hasUpdateDifMethods}
            />
          </div>
        </div>
      )}
    </PaymentType>
  );
};

CashMethod.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
  hasUpdateDifMethods: PropTypes.bool,
};

CashMethod.defaultProps = {
  name: 'cash',
};

export default CashMethod;
