import React, { useEffect } from 'react';
import './Payment.scss';
import { useFormikContext } from 'formik';
import { Checkbox } from '@casanova/casanova-common';
import {
  CardMethod,
  CashMethod,
  DepositMethod,
  TransferMethod,
  InvoicePayment,
  Holdback,
  obtenerHoraActual,
} from '..';
import i18n from '@i18n';
import { number2mxn } from 'utils/currency';
import _isEmpty from 'lodash/isEmpty';
import { MultiPaymentProvider } from '../hooks/context';
import useMultiPayment from '../hooks/useMultiPayment';
import Bank from '../Bank';

const MultiPaymentSection = () => {
  const { errors, values, setFieldValue } = useFormikContext();
  const {
    hasTotalPay,
    hasUpdateDifMethods,
    handleDifMethodsChange,
    touched,
    onUpdateForm,
    fetchCatalog,
    isJuridical,
  } = useMultiPayment();

  const setPaymentHour = () =>
    setInterval(() => {
      setFieldValue('paymentTime', obtenerHoraActual());
    }, 1000);

  useEffect(() => {
    setPaymentHour();
    return clearInterval(setPaymentHour);
  }, []);

  useEffect(() => {
    if (_isEmpty(values.selectedMethods)) {
      clearInterval(setPaymentHour);
    }
  }, [values.selectedMethods]);

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/banks',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-fiscal-regime/natural-person',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-fiscal-regime/juridical-person',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/bank-card-types',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/payment-forms',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/payment-methods',
      catalogPath: 'pricequotes',
    });
    fetchCatalog('branches');
  }, []);

  useEffect(() => {
    if (values.invoiceTaxRegime !== '') {
      fetchCatalog({
        catalogId: 'catalogs/cfdi-uses/fiscal-regime',
        params: {
          uuid: values.invoiceTaxRegime,
          isJuridical,
        },
        catalogPath: 'pricequotes',
      });
    }
  }, [values.invoiceTaxRegime]);

  return (
    <>
      <Bank />
      <div className="payment-page">
        <div className="payment-page__main">
          <div className="payment-page__main__title my-4 d-flex justify-content-between align-items-center">
            <p>Método de pago</p>
            {hasUpdateDifMethods && <p>{number2mxn(hasTotalPay)}</p>}
            <div>
              <Checkbox
                name="diffMethods"
                label="Pagar con diferentes métodos"
                onChange={handleDifMethodsChange}
              />
            </div>
          </div>
          <CardMethod index={0} key={0} />
          {hasUpdateDifMethods &&
            [1, 2].map((index) => <CardMethod index={index} key={index} />)}
          <DepositMethod />
          <TransferMethod />
          <CashMethod />
        </div>
        {values.needHoldback && <Holdback />}
        <InvoicePayment onUpdateForm={onUpdateForm} />
        {errors.methodSelected && touched.methodSelected && (
          <div className="col-form-error text-danger">
            {errors.methodSelected}
          </div>
        )}
      </div>
    </>
  );
};

const MultiPayments = ({
  touched,
  onUpdateForm,
  uuidCustomer,
  fetchCatalog,
  isJuridical,
}) => {
  return (
    <MultiPaymentProvider
      props={{
        touched,
        onUpdateForm,
        uuidCustomer,
        fetchCatalog,
        isJuridical,
      }}
    >
      <MultiPaymentSection />
    </MultiPaymentProvider>
  );
};

MultiPayments.defaultProps = {
  maxSteps: 2,
  step: 2,
  title: i18n('RESERVATIONS__STEPPER__TITLE'),
  subtitle: i18n('RESERVATIONS__STEPPER__SUBTITLE'),
};

export default MultiPayments;
