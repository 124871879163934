import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index.js';
import * as yup from 'yup';
import { contractDetailTouched } from './config';
import multiPaymentSchema from '../sectionsMethodsPayment/MultiPayments/schema';

export const contractsDetailsSchema = () =>
  yup.object().shape({
    charges: yup.number(),
    payment: yup
      .number()
      .required(REQUIRED_MESSAGE)
      .test(
        'invalidPrice',
        'El pago no puede ser mayor al monto del adeudo',
        function (value) {
          if (!value) return true;
          return value <= this.parent.charges;
        }
      ),
  });

export const addContractSchema = (values, validateYupSchema) => {
  const { contractsDetails } = values;
  const index = contractsDetails.length - 1;
  const lastContract = contractsDetails[index];
  const someFieldWithValue = Object.values(lastContract).some((value) => value);

  if (lastContract && someFieldWithValue) {
    contractsDetails.forEach((cd) =>
      validateYupSchema(cd, contractsDetailsSchema({ parent: values }))
    );
  }
};

const schema = yup.object().shape({
  contractsDetails: yup.array(),
  customerUuid: yup.string(),
  customer: yup.string(),
  amount: yup
    .number()
    .required(REQUIRED_MESSAGE)
    .test(
      'totalAmountNotAssigned',
      'La suma de los pagos debe ser igual al monto total',
      function (value) {
        const { contractsDetails } = this.parent;
        const totalAmountAssigned = contractsDetails.reduce(
          (acc, cd) => acc + cd.payment,
          0
        );
        return value === totalAmountAssigned;
      }
    ),
  ...multiPaymentSchema,
});

export default schema;
