const RESET = 'payments/RESET';

const GET_CONTRACT_BY_SEARCH = 'payments/GET_CONTRACT_BY_SEARCH';
const GET_CONTRACT_BY_SEARCH_SUCCEED =
  'payments/GET_CONTRACT_BY_SEARCH_SUCCEED';
const GET_CONTRACT_BY_SEARCH_FAILED = 'payments/GET_CONTRACT_BY_SEARCH_FAILED';

const GET_MULTI_CONTRACT_PAYMENT = 'payments/GET_MULTI_CONTRACT_PAYMENT';
const GET_MULTI_CONTRACT_PAYMENT_SUCCEED =
  'payments/GET_MULTI_CONTRACT_PAYMENT_SUCCEED';
const GET_MULTI_CONTRACT_PAYMENT_FAILED =
  'payments/GET_MULTI_CONTRACT_PAYMENT_FAILED';
const SAVE_MULTI_CONTRACT_PAYMENT = 'payments/SAVE_MULTI_CONTRACT_PAYMENT';
const SAVE_MULTI_CONTRACT_PAYMENT_SUCCEED =
  'payments/SAVE_MULTI_CONTRACT_PAYMENT_SUCCEED';
const SAVE_MULTI_CONTRACT_PAYMENT_FAILED =
  'payments/SAVE_MULTI_CONTRACT_PAYMENT_FAILED';

const CLOSE_RESPONSE_DIALOG = 'payments/CLOSE_RESPONSE_DIALOG';
const CLOSE_RESPONSE_DIALOG_VALID_CODE =
  'customers/CLOSE_RESPONSE_DIALOG_VALID_CODE';

const VALID_CODE_FAILED = 'VALID_CODE_FAILED';

const GET_CONTRACT_DETAILS = 'payments/GET_CONTRACT_DETAILS';
const GET_CONTRACT_DETAILS_SUCCEED = 'payments/GET_CONTRACT_DETAILS_SUCCEED';
const GET_CONTRACT_DETAILS_FAILED = 'payments/GET_CONTRACT_DETAILS_FAILED';
const CLEAN_CONTRACT_DETAILS = 'payments/CLEAN_CONTRACT_DETAILS';

const GET_CONTRACT_MOVEMENT_LIST = 'payments/GET_CONTRACT_MOVEMENT_LIST';
const GET_CONTRACT_MOVEMENT_LIST_SUCCEED =
  'payments/GET_CONTRACT_MOVEMENT_LIST_SUCCEED';
const GET_CONTRACT_MOVEMENT_LIST_FAILED =
  'payments/GET_CONTRACT_MOVEMENT_LIST_FAILED';
const SET_MOVEMENT_LIST_PARAMS = 'payments/SET_MOVEMENT_LIST_PARAMS';
const SET_MOVEMENT_LIST_PAGE = 'payments/SET_MOVEMENT_LIST_PAGE';

const SET_MOVEMENT_TYPES = 'payments/SET_MOVEMENT_TYPES';

const SAVE_PAYMENT = 'payments/SAVE_PAYMENT';
const SAVE_PAYMENT_SUCCEED = 'payments/SAVE_PAYMENT_SUCCEED';
const SAVE_PAYMENT_FAILED = 'payments/GET_LOAN_DETAILS_FAILED';

const SET_CANCEL_MOVEMENT = 'payments/SET_CANCEL_MOVEMENT';
const SET_CANCEL_MOVEMENT_SUCCESS = 'payments/SET_CANCEL_MOVEMENT_SUCCESS';
const SET_CANCEL_MOVEMENT_FAILED = 'payments/SET_CANCEL_MOVEMENT_FAILED';

export default {
  GET_CONTRACT_BY_SEARCH,
  GET_CONTRACT_BY_SEARCH_SUCCEED,
  GET_CONTRACT_BY_SEARCH_FAILED,
  GET_MULTI_CONTRACT_PAYMENT,
  GET_MULTI_CONTRACT_PAYMENT_SUCCEED,
  GET_MULTI_CONTRACT_PAYMENT_FAILED,
  SAVE_MULTI_CONTRACT_PAYMENT,
  SAVE_MULTI_CONTRACT_PAYMENT_SUCCEED,
  SAVE_MULTI_CONTRACT_PAYMENT_FAILED,
  RESET,
  CLOSE_RESPONSE_DIALOG,
  CLOSE_RESPONSE_DIALOG_VALID_CODE,
  VALID_CODE_FAILED,
  GET_CONTRACT_DETAILS,
  GET_CONTRACT_DETAILS_SUCCEED,
  GET_CONTRACT_DETAILS_FAILED,
  CLEAN_CONTRACT_DETAILS,
  GET_CONTRACT_MOVEMENT_LIST,
  GET_CONTRACT_MOVEMENT_LIST_SUCCEED,
  GET_CONTRACT_MOVEMENT_LIST_FAILED,
  SET_MOVEMENT_LIST_PARAMS,
  SET_MOVEMENT_LIST_PAGE,
  SET_MOVEMENT_TYPES,
  SAVE_PAYMENT,
  SAVE_PAYMENT_SUCCEED,
  SAVE_PAYMENT_FAILED,
  SET_CANCEL_MOVEMENT,
  SET_CANCEL_MOVEMENT_SUCCESS,
  SET_CANCEL_MOVEMENT_FAILED,
};
