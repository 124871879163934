import moment from 'moment';
import _get from 'lodash/get';
import { formatDateWithTime } from 'utils/date';
import { YYYY_MM_DD } from '@casanova/casanova-common/lib/utils/date';
import { timeZone } from '@casanova/casanova-common/lib/utils/constants/index.js';
import { VEHICLE_STATUS_FIELDS } from './constants';

export const vehicleDetailTransform = (values) => {
  const transformed = {
    km: _get(values, 'km', ''),
    brand: _get(values, 'brand.uuid', ''),
    line: _get(values, 'line.uuid', ''),
    version: _get(values, 'version.uuid', ''),
    model: _get(values, 'model.uuid', ''),
    color: _get(values, 'color.uuid', ''),
    status: _get(values, 'status.uuid', ''),
    subStatus: _get(values, 'subStatus.uuid', ''),
    businessArea: _get(values, 'businessAreaUuid', ''),
    warehouse: _get(values, 'warehouse.uuid', ''),
    vehicleUuid: _get(values, 'vehicleUuid', ''),
    fuelType: _get(values, 'fuelType.uuid', ''),
    transmissionType: _get(values, 'transmissionType.uuid', ''),
    serialNumber: _get(values, 'serialNumber', ''),
    plateNumber: _get(values, 'plateNumber', ''),
    customer: _get(values, 'contractActive.naturalPersonName', 'SIN ASIGNAR'),
    contractNumber: _get(values, 'contractActive.contract', 'SIN ASIGNAR'),
    lastPlateNumber: _get(values, 'plateNumber', ''),
    category: _get(values, 'category.uuid', ''),
  };
  return transformed;
};

export const transformVehicleHistoricDetails = (historicDetails) => {
  const transformedHistoricDetails = historicDetails.map(
    (statusElement, i) => ({
      ...statusElement,
      [VEHICLE_STATUS_FIELDS.STATUS_DATE]: moment(statusElement.statusSetDate)
        .tz(timeZone)
        .format(YYYY_MM_DD),
      [VEHICLE_STATUS_FIELDS.USER]: statusElement.user.fullname,
      [VEHICLE_STATUS_FIELDS.ACTIVE]: statusElement.active,
      [VEHICLE_STATUS_FIELDS.STATUS]: statusElement.name,
      id: i + 1,
    })
  );

  return transformedHistoricDetails;
};

export const transformVehicleContracts = (contracts) => {
  const transformedHistoricDetails = contracts.map((contract) => {
    const { customer } = contract;
    const customerFullName = `${customer.names} ${customer.lastName} ${
      customer.secondLastName || ''
    }`;
    return {
      ...contract,
      contractNumber: `Z${_get(contract, 'contractNumber', '').padStart(
        8,
        '0'
      )}`,
      id: _get(contract, 'contractNumber', ''),
      // customer: customerFullName,
      customer: _get(contract, 'customer', ''),
      initialDate: moment(contract.pickupDateTime)
        .tz(timeZone)
        .format(YYYY_MM_DD),
      returnDate: contract.returnDateTime
        ? moment(contract.returnDateTime).tz(timeZone).format(YYYY_MM_DD)
        : '',
      realReturnDate: contract.realReturnDateTime
        ? moment(contract.realReturnDateTime).tz(timeZone).format(YYYY_MM_DD)
        : '',
      branch: _get(contract, 'returnBranch.name', ''),
      apertureUser: _get(contract, 'createdBy', ''),
      closeUser: _get(contract, 'closedBy', ''),
      status:
        contract.reservationStatusName ||
        (contract.isActive ? 'Activo' : 'Cerrado'),
    };
  });

  return transformedHistoricDetails;
};

export const transformVehicleLoans = (loans) =>
  loans.map((loan) => ({
    id: loan.uuid,
    uuid: loan.uuid,
    folio: _get(loan, 'folio', ''),
    registerDate: moment(loan.createdAt).tz(timeZone).format(YYYY_MM_DD),
    departureDateAndTime: formatDateWithTime(
      loan.departureDate,
      loan.departureTime
    ),
    returnDateAndTime: formatDateWithTime(loan.returnDate, loan.returnTime),
    realReturnDateAndTime: loan.realReturnDate
      ? formatDateWithTime(loan.realReturnDate, loan.realReturnTime)
      : '',
    apertureUser: _get(loan, 'createdBy', ''),
    requester: _get(loan, 'requesterName', ''),
    status: _get(loan, 'loanStatus', {}),
  }));

const unityType = {
  'Automóviles Particulares': 'AP',
  'Devoluciones Gobierno': 'DG',
  Leasing: 'LS',
  Licitaciones: 'LC',
  'Renta Diaria': 'Z',
  'Cortesia': 'CR',
  'Apartado': "AP",
  'Subarrendados': "SB",
  Renting: 'RT',
  Utilitario: 'UT',
};

export const transformVehicleTransfers = (transfers) => {
  const transformedTransfers = transfers.map((transfer) => ({
    ...transfer,
    ...transfer,
    departureDateAndTime: formatDateWithTime(
      transfer.departureDate,
      transfer.departureTime
    ),
    arrivalDateAndTime: formatDateWithTime(
      transfer.arrivalDate,
      transfer.arrivalTime
    ),
    realArrivalDateAndTime: transfer.realArrivalDate
      ? formatDateWithTime(transfer.realArrivalDate, transfer.realArrivalTime)
      : '',
    id: transfer.uuid,
    createdAt: moment(transfer.createdAt).tz(timeZone).format(YYYY_MM_DD),
  }));

  return transformedTransfers;
};

export const transformVehicleVirtualContracts = (transfers) => {
  const transformedTransfers = transfers.map((transfer) => {
    return {
      ...transfer,
      folio: `${unityType[transfer.businessArea]}${transfer.folio.padStart(
        8,
        '0'
      )}`,
    };
  });

  return transformedTransfers;
};
