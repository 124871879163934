import React, { useState } from 'react';
import _get from 'lodash/get';
import { Summary } from '@reservations/sections';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { fetchCatalog } from 'store/commons/actions';
import { getDetail } from 'store/customers/actions';
import {
  setNewInvoicePayment,
  closeResponseDialog,
  setHasAddedBillingDetailsOfPayment,
} from 'store/reservations/actions';
import { withLayout } from 'hooks';
import { Blank } from 'components';
import Payment from './Payment';

const _ = undefined;
const reserve = true;
let priceVehicleRent = 0;

function mapStateToProps(state) {
  return {
    quoteValues: _get(state, 'reservations.quote.values', {}),
    loader: _get(state, 'commons.loader', false),
    reservationId: _get(state, 'reservations.reservation.id', ''),
    customer: _get(state, 'customers.customerDetail', {}),
    newInvoicePayment: _get(
      state,
      'reservations.reservation.newInvoicePayment',
      false
    ),
    customerJuridical: _get(
      state,
      'customers.customerDetail.data.juridicalPerson'
    ),

    confirmationMessage: false,
    middleCol: 2,
    rightCol: 6,
    left: <Blank />,
    onCancelPath: '/reservations',
    responseDialog: _get(state, 'reservations.responseDialog', {}),
    priceVehicleRent,
    withoutPayment: _get(
      state,
      'reservations.reservation.payment.withoutPayment',
      false
    ),
    responseDialogReservationVehicle: _get(
      state,
      'reservations.responseDialogReservationVehicle',
      {}
    ),
    extendContract: _get(state, 'reservations.contract.extendContract', {}),
  };
}

function mapDispatchToProps(dispatch) {
  const getDispatch = (action) => (...args) => dispatch(action(...args));

  return {
    fetchCatalog: getDispatch(fetchCatalog),
    getCustomer: getDispatch(getDetail),
    setNewInvoicePayment: getDispatch(setNewInvoicePayment),
    closeResponseDialog: getDispatch(closeResponseDialog),
    onCancel: () => dispatch(setNewInvoicePayment(false)),
    setHasAddedBillingDetailsOfPayment: getDispatch(
      setHasAddedBillingDetailsOfPayment
    ),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const newDispatchProps = { ...dispatchProps };

  if (!stateProps.newInvoicePayment) {
    delete newDispatchProps.onCancel;
  }

  return {
    ...stateProps,
    ...newDispatchProps,
    ...ownProps,
  };
}

function obtenerPrecio(total) {
  priceVehicleRent = total;
}

export const PaymentPage = withLayout(
  compose(connect(mapStateToProps, mapDispatchToProps, mergeProps))(Payment),
  _,
  <Summary isReserving obtenerPrecio={reserve && obtenerPrecio} />,
  'col-8 col-xl-9'
);

export default PaymentPage;
