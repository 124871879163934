import PaymentsAndChargesModuleIcon from '@casanova/casanova-common/lib/Icons/PaymentsAndChargesModule';
import i18n from '@i18n';
import { PAYMENTS_MODULE, VIEW_PAYMENTS } from 'utils/roles/permissions';
import { withReconcileNav } from 'containers/Breadcrumb';
import { withRoleRestrictions } from 'utils/auth';
import { validateRolePermissions } from 'utils/roles';
import { ContractSearch, ContractDetail } from '@payments';
import MultiContractPayment from '../../containers/Payments/MultiContractPayment';

export const payments = {
  moduleName: PAYMENTS_MODULE,
  route: '/payments-and-charges',
  label: i18n('PAYMENTS_AND_CHARGES__MENU_LABEL'),
  icon: PaymentsAndChargesModuleIcon,
  show: validateRolePermissions(VIEW_PAYMENTS),
  main: 'all',
  children: {
    all: {
      route: '',
      label: '',
      component: withReconcileNav(
        'payments',
        'all'
      )(withRoleRestrictions(ContractSearch)),
    },
    detail: {
      route: `/:id/detail`,
      label: 'MULTIPLES CONTRATOS',
      format: (id) => `/payments-and-charges/${id}/detail`,
      onlyRoute: true,
      component: withReconcileNav(
        'payments',
        'detail'
      )(withRoleRestrictions(ContractDetail)),
    },
    multiContractPayment: {
      route: `/:id/multi-contract-payment`,
      label: i18n('COMMONS_DETAIL').toUpperCase(),
      format: (id) => `/payments-and-charges/${id}/multi-contract-payment`,
      onlyRoute: true,
      component: withReconcileNav(
        'payments',
        'detail'
      )(withRoleRestrictions(MultiContractPayment)),
    },
  },
};
