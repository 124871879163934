import { useEffect, useState, useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import {
  Icons,
  Section,
  MaskedInput as Input,
  withForm,
  Button,
  NumberInput,
  Modal,
} from '@casanova/casanova-common';
import { closeResponseDialog } from 'store/payments/actions';
import { BackButton, PreventChange } from 'components';
import i18n from '@i18n';
import { number2mxn } from 'utils/currency';
import config from './config';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import RoleVerifier from 'components/RoleVerifier';
import { CREATE_RESERVATION } from 'utils/roles/permissions/contractsPermissions';
import { useFormikContext } from 'formik';
import { MultiPayments } from '../sectionsMethodsPayment';
import ResponseDialog from 'components/ResponseDialog';
// import { validateRolePermissions } from 'utils/roles';

function MultiContractPayment({
  multiContractPaymentDetails,
  getMultiPaymentContract,
  cleanContractDetails,
  openConfirmationMessage,
  match,
  history,
  onUpdateForm,
  fetchCatalog,
  loader,
  responseDialogMultiContract,
  getDetail,
}) {
  const dispatch = useDispatch();
  const customerUuid = useMemo(() => match?.params?.id, [match?.params?.id]);
  const hidePaymentModal =
    openConfirmationMessage || loader || responseDialogMultiContract.open;
  const {
    values,
    errors,
    touched,
    setFieldValue,
    submitForm,
    dirty,
    isSubmitting,
  } = useFormikContext();
  const [showModal, setShowModal] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const hasPreventChange = dirty && !isSubmitting;

  const totalAssigned = values.contractsDetails.reduce(
    (acc, contract) => acc + contract.payment,
    0
  );
  const toAssigned = values.amount - totalAssigned;
  const labelAssignedError =
    toAssigned >= 0 ? 'Faltante por asignar: ' : 'Excedente asignado: ';

  const handleClickPayment = () => {
    setShowModal(true);
    getDetail({ id: customerUuid, withLoader: true });
  };

  const handlePaymentClose = () => {
    setShowModal(false);
  };

  const handleBack = () => {
    // cleanContractDetails();
    setTimeout(() => {
      history.push('/payments-and-charges/');
    }, 1);
  };

  useEffect(() => {
    if (_isEmpty(multiContractPaymentDetails) && !hasFetched) {
      getMultiPaymentContract(customerUuid);
    } else {
      setHasFetched(true);
    }
  }, [getMultiPaymentContract, customerUuid, hasFetched]);

  useEffect(() => {
    if (!_isEmpty(multiContractPaymentDetails)) {
      onUpdateForm(multiContractPaymentDetails);
    }
  }, [multiContractPaymentDetails]);

  const priceEditor = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
        mode="currency"
        currency="USD"
        locale="en-US"
        min={0}
        max={options.rowData.charges}
      />
    );
  };

  const contractNumberTemplate = ({ contractNumber }) => {
    return 'Z' + contractNumber.padStart(8, '0');
  };

  const chargeBodyTemplate = ({ charges }) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Math.abs(charges));
  };

  const paymentBodyTemplate = ({ payment }) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(payment);
  };

  const balanceBodyTemplate = ({ balance }) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(balance);
  };

  const onRowEditComplete = (e) => {
    let _contractDetails = [...values.contractsDetails];
    let { newData, index } = e;

    _contractDetails[index] = newData;
    _contractDetails[index].balance = newData.charges - newData.payment;

    setFieldValue('contractsDetails', _contractDetails);
  };

  const handleClickSubmit = () => {
    submitForm();
  };

  const handleClickCancel = () => {
    setShowModal(false);
  };

  const handleOnCloseConfirm = () => {
    setShowModal(false);
    dispatch(closeResponseDialog());
  };

  return (
    <>
      <PreventChange preventChange={hasPreventChange}>
        <ResponseDialog
          open={responseDialogMultiContract.open}
          success={responseDialogMultiContract.success}
          errorCode={responseDialogMultiContract.errorCode}
          successTitle="CONTRATO MODIFICADO"
          successLabel={i18n('COMMONS__CLOSE__TEXT')}
          successMessage="El contrato fue modificado exitosamente"
          onError={handleOnCloseConfirm}
          onClose={handleOnCloseConfirm}
          onSuccess={handleOnCloseConfirm}
          errorLabel={i18n('COMMONS__CLOSE__TEXT')}
        />
        <Modal
          open={showModal}
          onClose={handlePaymentClose}
          bigModal
          // topContainerClassName={hideModal || loader ? 'd-none' : ''}
          topContainerClassName={hidePaymentModal ? 'd-none' : ''}
        >
          <Section
            title={i18n(`PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__TITLE_PAYMENT`)}
          >
            <div className="row">
              <div className="col-4">
                <NumberInput
                  label={`${i18n(
                    'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__AMOUNT'
                  )}*`}
                  placeholder={`${i18n(
                    'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__AMOUNT'
                  )}*`}
                  decimals={2}
                  max={9_999_999_999}
                  name="amount"
                  disabled={true}
                />
              </div>
            </div>
            <MultiPayments
              touched={touched}
              onUpdateForm={onUpdateForm}
              uuidCustomer={customerUuid}
              fetchCatalog={fetchCatalog}
              isJuridical={true}
            />
            <div className="row">
              <div className="col-3">
                <span style={{ marginRight: '1rem' }}>
                  <Icons.Coment height="2rem" />
                </span>
                * Campos obligatorios.
              </div>
              <div className="col"></div>
              <div className="col-3">
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2 mb-sm-0 pl-0">
                    <Button outline block onClick={handleClickCancel}>
                      Cancelar
                    </Button>
                  </div>
                  <div className="col-12 col-sm-6 pl-0">
                    <Button type="submit" block onClick={handleClickSubmit}>
                      Guardar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </Modal>
        <Section
          title={i18n('PAYMENTS__CONTRACT_DETAIL__TITLE')}
          className="MultiContractPayment"
        >
          <div className="row">
            <div className="col-md-4">
              <Input
                label={i18n('COMMONS__CUSTOMER')}
                placeholder={i18n('COMMONS__CUSTOMER')}
                disabled={true}
                name="customer"
              />
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <NumberInput
                label={`${i18n(
                  'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__AMOUNT'
                )}*`}
                placeholder={`${i18n(
                  'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__AMOUNT'
                )}*`}
                decimals={2}
                max={9_999_999_999}
                name="amount"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <DataTable
                value={values.contractsDetails}
                editMode="row"
                dataKey="contractUuid"
                onRowEditComplete={onRowEditComplete}
                tableStyle={{ minWidth: '50rem' }}
              >
                <Column
                  field="contractNumber"
                  header="Número de Contrato"
                  body={contractNumberTemplate}
                  style={{ width: '20%' }}
                ></Column>
                <Column
                  field="contractStatus"
                  header="Estatus del Contrato"
                  style={{ width: '20%' }}
                ></Column>
                <Column
                  field="charges"
                  header="Total de Cargos"
                  body={chargeBodyTemplate}
                  style={{ width: '20%' }}
                ></Column>
                <Column
                  field="payment"
                  header="Abonos"
                  body={paymentBodyTemplate}
                  editor={(options) => priceEditor(options)}
                  style={{ width: '20%' }}
                ></Column>
                <Column
                  field="balance"
                  header="Saldo"
                  body={balanceBodyTemplate}
                  style={{ width: '20%' }}
                ></Column>
                <Column
                  headerStyle={{ width: '10%', minWidth: '8rem' }}
                  bodyStyle={{ textAlign: 'center' }}
                  rowEditor
                ></Column>
              </DataTable>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col"></div>
            <div className="col-md-4 flex justify-content-end text-red-500 text-lg">
              {values.amount &&
                errors.amount &&
                labelAssignedError + number2mxn(toAssigned)}
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <BackButton onClick={handleBack} />
            </div>
            <div className="col" />
            <div className="col-md-3 col-lg-2 d-flex justify-content-between flex-wrap p-0 px-1">
              <RoleVerifier identifier={CREATE_RESERVATION}>
                <Button
                  block
                  className="PaymentButton mt-0"
                  onClick={handleClickPayment}
                  disabled={errors.amount}
                >
                  Abono
                </Button>
              </RoleVerifier>
            </div>
          </div>
        </Section>
      </PreventChange>
    </>
  );
}

export default withForm({ config })(MultiContractPayment);
